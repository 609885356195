import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { UserActivity } from '../models';
import { Repository } from '../database/repository';

@Injectable({
  providedIn: 'root',
})
export class UserActivityRepository extends Repository<UserActivity, 'id', true> {

  constructor(db: NgxIndexedDBService) {
    super(db, 'userActivity', 'id');
  }

}
