import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { PinchZoomComponent } from '@meddv/ngx-pinch-zoom';

@Component({
  selector: 'core-menu-image',
  templateUrl: './menu-image.component.html',
  styleUrls: ['./menu-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PinchZoomComponent,
  ],
})
export class MenuImageComponent {

  public readonly pinchZoomProperties = {
    wheel: false,
    autoZoomOut: true,
    backgroundColor: 'rgba(240, 240, 243, 0.9)',
  };

  public url = input.required<string | SafeUrl>();

}
