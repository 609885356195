import { DestroyRef, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, forkJoin, from, merge, Observable, of, shareReplay } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, tap, toArray } from 'rxjs/operators';
import { arrayDiff, arrayDiffIdCompare, fromArray, isUndefined, tapSubscribed } from '../utils';
import { FileCacheModel, ISpecial, ISpecialRaw } from '../models';
import { FileCacheService } from './file-cache.service';
import { WatchdogService } from './watchdog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WebsocketService } from './websocket.service';
import { SpecialsRepository } from '../repositories/specials.repository';
import { DomSanitizer } from '@angular/platform-browser';
import { FileCacheRepository } from '../repositories/file-cache.repository';
import { hasOne } from '../database/repository.operators';
import { AuthService } from './auth.service';
import { SwarmService } from './swarm.service';

interface ToUpdate<T> {
  origin: T;
  value: T;
}

@Injectable()
export class SpecialsService {

  private readonly logger = this.watchdog.tag('Specials', 'lime');
  public readonly loading$ = new BehaviorSubject<Boolean>(true);

  public readonly specials$ = this.specialsRepository.all$().pipe(
    tapSubscribed(() => {
      this.loading$.next(true);
    }),
    hasOne(
      this.filesCacheRepository,
      'url',
      'content',
      'contentLocal'
    ),
    map((specials) => {
      if (specials === undefined) {
        return undefined;
      }

      return (Array.isArray(specials) ? specials : [specials]).map(special => {
        const contentFile = special.contentLocal
          ? new FileCacheModel(special.contentLocal)
          : undefined;

        return {
          ...special,
          contentLocal: contentFile
            ? this.domSanitizer.bypassSecurityTrustUrl(contentFile.objectUrl)
            : undefined
        } satisfies ISpecial;
      })
    }),
    tap(() => this.loading$.next(false)),
    shareReplay(1),
  );

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly auth: AuthService,
    private readonly swarm: SwarmService,
    private readonly filesCache: FileCacheService,
    private readonly domSanitizer: DomSanitizer,
    private readonly specialsRepository: SpecialsRepository,
    private readonly filesCacheRepository: FileCacheRepository,
    private readonly watchdog: WatchdogService,
    private readonly webSocket: WebsocketService,
  ) {}

  public initialize(): void {
    this.swarm.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.auth.logouted$),
      switchMap(() => this.clear()),
      tap(() => this.logger.info('Cleared specials on logout')),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.webSocket.messages$.pipe(
      filter((response) => response.type === 'tableInfo'),
      switchMap((response) => {
        if (!response.data.specials) {
          return this.sync([]);
        }

        return this.sync(response.data.specials);
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  public sync(specialsWS: ISpecialRaw[]): Observable<ISpecialRaw[]> {
    return this.specialsRepository.all$().pipe(
      take(1),
      tapSubscribed(() => {
        this.logger.debug('Start sync special');
        this.loading$.next(true);
      }),
      switchMap((currentSpecials) => {
        const add = arrayDiff(specialsWS, currentSpecials, arrayDiffIdCompare);
        const del = arrayDiff(currentSpecials, specialsWS, arrayDiffIdCompare);
        const upd = currentSpecials.reduce<ToUpdate<ISpecialRaw>[]>((acc, origin) => {
          const value = specialsWS.find((d) => d.id === origin.id);
          if (value && JSON.stringify(value) !== JSON.stringify(origin)) {
            acc.push({
              origin,
              value,
            });
          }

          return acc;
        }, []);

        return merge(
          this.toAdd(add),
          this.toUpdate(upd),
          this.toDelete(del),
        );
      }),
      tap(() => this.loading$.next(false))
    );
  }

  private toAdd(specials: ISpecialRaw[]): Observable<ISpecialRaw[]> {
    if (specials.length === 0) {
      return of([]);
    }

    return from(specials).pipe(
      tap((special) => this.logger.debug('Special to add', special)),
      mergeMap((special) => {
        return this.filesCache.downloadFile(special.content).pipe(
          map(() => special),
        );
      }),
      mergeMap((special) => this.specialsRepository.add$(special)),
      toArray(),
      tap((special) => this.logger.debug('Special added', special)),
    );
  }

  private toUpdate(specials: ToUpdate<ISpecialRaw>[]): Observable<ISpecialRaw[]> {
    return fromArray(specials).pipe(
      tap((special) => this.logger.debug('Special to update', special)),
      mergeMap(({ origin, value }) =>
        this.specialsRepository.update$(value).pipe(
          map((special) => ({ origin, special })),
        )
      ),
      mergeMap(({ origin, special }) => {
        if (!special) {
          return EMPTY;
        }

        if (special.content === origin.content) {
          return of(special);
        }

        return this.filesCacheRepository.delete$(origin.content).pipe(
          take(1),
          mergeMap(() => this.filesCache.downloadFile(special.content)),
          map(() => special),
        );
      }),
      toArray(),
      tap((special) => this.logger.debug('Special updated', special)),
    );
  }

  private toDelete(specials: ISpecialRaw[]): Observable<ISpecialRaw[]> {
    return fromArray(specials).pipe(
      tap((special) => this.logger.debug('Special to delete', special)),
      mergeMap((special) => {
        return this.specialsRepository.delete$(special.id).pipe(
          map(() => special),
        )
      }),
      mergeMap((special) => {
        if (!special) {
          return EMPTY;
        }

        return this.filesCacheRepository.delete$(special.content).pipe(
          take(1),
          map(() => special),
        );
      }),
      toArray(),
      tap((special) => this.logger.debug('Special deleted', special)),
    );
  }

  private clear(): Observable<string[] | null> {
    return this.specialsRepository.all$().pipe(
      take(1),
      switchMap((specials) => {
        if (isUndefined(specials)) {
          return of(specials);
        }

        const urls = specials.map((special) => {
          return special.content;
        }).flat();

        return forkJoin(urls.map((url) => {
          return this.filesCacheRepository.delete$(url).pipe(
            map(() => url),
          );
        }));
      }),
      switchMap((urls) => {
        return this.specialsRepository.clear$().pipe(
          map(() => urls),
        );
      })
    );
  }

}
