import { inject, PLATFORM_ID, provideAppInitializer } from '@angular/core';
import {
  CORE_APP_ENV,
  CORE_APP_NAME,
  CORE_APP_VERSION,
  CORE_DEBUG,
  CORE_FEATURE_TOGGLE,
  CORE_STARTUP_DATE,
  CORE_STARTUP_ID,
} from './core.tokens';
import {
  CallWaiterService,
  DeviceWorkScheduleService,
  HappyHoursService,
  InteractionService,
  Intercom,
  InvoiceQrcodeImageService,
  MenusService,
  NetworkService,
  RestaurantTableService,
  SpecialsService,
  SwarmService,
  UpdateService,
  WatchdogService,
  WebsocketService,
  WidgetUiConfigRef,
} from './lib/services';

export const provideCoreInitialize = () => provideAppInitializer(() => {
  const id = inject(CORE_STARTUP_ID);
  const date = inject(CORE_STARTUP_DATE);
  const name = inject(CORE_APP_NAME);
  const version = inject(CORE_APP_VERSION);
  const environment = inject(CORE_APP_ENV);
  const debug = inject(CORE_DEBUG);
  const featureToggle = inject(CORE_FEATURE_TOGGLE);
  const platformId = inject(PLATFORM_ID);
  const update = inject(UpdateService);
  const network = inject(NetworkService);
  const swarm = inject(SwarmService);
  const interaction = inject(InteractionService);
  const intercom = inject(Intercom);
  const websocket = inject(WebsocketService);
  const watchdog = inject(WatchdogService);
  const restaurantTable = inject(RestaurantTableService);
  const widgetUiConfig = inject(WidgetUiConfigRef);
  const callWaiter = inject(CallWaiterService);
  const deviceWorkSchedule = inject(DeviceWorkScheduleService);
  const specials = inject(SpecialsService);
  const menu = inject(MenusService);
  const happyHours = inject(HappyHoursService);
  const invoiceQrcode = inject(InvoiceQrcodeImageService);

  const log = watchdog.tag('App', 'red');

  log.info('Initializing', {
    id,
    date,
    name,
    version,
    environment,
    debug,
    featureToggle,
    platform: platformId,
  });

  swarm.initialize();
  intercom.initialize();
  websocket.initialize();
  update.initialize();
  network.initialize();
  interaction.initialize();
  deviceWorkSchedule.initialize();
  restaurantTable.initialize();
  widgetUiConfig.initialize();
  callWaiter.initialize();
  menu.initialize();
  happyHours.initialize();
  specials.initialize();
  invoiceQrcode.initialize();
});
