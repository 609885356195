import { DestroyRef, Inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, fromEvent, shareReplay } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { WatchdogService } from './watchdog.service';
import { CORE_STARTUP_ID } from '../../core.tokens';

type FilterNever<T, FT = never> = {
  [K in keyof T as T[K] extends FT ? never : K]: T[K];
};

type SwarmMessageTypesMap = {
  'member.new': never,
  'member.leader': never,
  'member.handshake': never,
  'member.remove': never,
  'auth.code': string | null,
  'waiter.call': {
    type: 'main' | 'requestBill' | 'anotherRound';
    status: boolean;
  },
  'repository.event': {
    repository: string;
    event: {
      event?: string;
      key?: unknown;
      value?: unknown;
    };
  },
}

type SwarmMessageTypesMapWithPayload = FilterNever<SwarmMessageTypesMap>;
type SwarmMessageTypesMapWithoutPayload = Omit<SwarmMessageTypesMap, keyof SwarmMessageTypesMapWithPayload>;

export type SwarmMessageTypes = keyof SwarmMessageTypesMap;
export type SwarmMessageTypesWithPayload = keyof SwarmMessageTypesMapWithPayload;
export type SwarmMessageTypesWithoutPayload = keyof SwarmMessageTypesMapWithoutPayload;

export type SwarmMessageData<T extends SwarmMessageTypes> = SwarmMessageTypesMap[T];

export type SwarmMessageTypesMapObject<T extends SwarmMessageTypes> = {
  [K in T]: SwarmMessageData<K> extends never ? {
    type: K;
  } : {
    type: K;
    data: SwarmMessageData<K>;
  };
}[T];

export type SwarmMessage = {
  from: string;
  to?: string;
} & SwarmMessageTypesMapObject<SwarmMessageTypes>;

export type SwarmMember = {
  self: boolean;
  leader: boolean;
};

export type SwarmMembers = ReadonlyMap<string, SwarmMember>;

class MembersSubject extends BehaviorSubject<SwarmMembers> {

  constructor(
    private readonly id: string,
  ) {
    super(new Map<string, SwarmMember>([
      [id, { self: true, leader: false }],
    ]));
  }

  public set(id: string): void {
    const newValue = new Map<string, SwarmMember>(this.value);

    this.next(
      newValue.set(id, {
        self: id === this.id,
        leader: false,
      }),
    );
  }

  public setLeader(id: string): void {
    const newValue = new Map<string, SwarmMember>(this.value);

    newValue.forEach((member, memberId) => {
      if (memberId !== id) {
        newValue.set(memberId, {
          ...member,
          leader: false,
        });
      }
    });

    this.next(
      newValue.set(id, {
        self: id === this.id,
        leader: true,
      }),
    );
  }

  public delete(member: string): void {
    const newValue = new Map<string, SwarmMember>(this.value);
    newValue.delete(member);
    this.next(newValue);
  }

}

@Injectable()
export class SwarmService {

  private readonly logger = this.watchdog.tag('Swarm', 'red');
  private readonly channel = new BroadcastChannel<SwarmMessage>('orchestrator');
  private readonly elector = createLeaderElection(this.channel);

  private readonly leaderSubject = new BehaviorSubject<boolean | undefined>(undefined);
  private readonly membersSubject = new MembersSubject(this.id);
  public readonly leader$ = this.leaderSubject.asObservable().pipe(
    distinctUntilChanged(),
  );
  public readonly loading$ = this.leader$.pipe(
    map((leader) => leader === undefined),
    distinctUntilChanged(),
  );
  public readonly members$ = this.membersSubject.asObservable();
  public readonly messages$ = fromEvent(this.channel, 'message').pipe(
    filter((message) => {
      return message.to === undefined || message.to === this.id;
    }),
    shareReplay(100),
  );

  constructor(
    @Inject(CORE_STARTUP_ID) public readonly id: string,
    private readonly destroyRef: DestroyRef,
    private readonly watchdog: WatchdogService,
  ) {}

  public initialize(): void {
    this.logger.info('Initializing');

    this.broadcast('member.new').then(() => {
      this.logger.debug('Broadcasted new member');
    });

    this.elector.onduplicate = async (message) => {
      this.logger.warn('Duplicate leader detected', message);
      await this.channel.close();
      window.location.reload();
    };

    fromPromise(this.elector.awaitLeadership()).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.logger.info('Became a leader');

      this.leaderSubject.next(true);
      this.membersSubject.setLeader(this.id);

      this.broadcast('member.leader').then(() => {
        this.logger.debug('Broadcasted new leader');
      });
    });

    fromEvent(window, 'beforeunload').pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(async () => {
      await this.close();
      this.logger.info('Closed');
    });

    this.messages$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((message) => {
      this.onMessage(message);
    });

    this.membersSubject.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((members) => {
      this.logger.debug('Members', members);
    });
  }

  public get leader(): boolean {
    return !!this.leaderSubject.value;
  }

  public get members(): SwarmMembers {
    return this.membersSubject.value;
  }

  public get leaderId(): string | undefined {
    if (this.leader) {
      return this.id;
    }

    for (const [id, member] of this.members) {
      if (member.leader) {
        return id;
      }
    }

    return undefined;
  }

  public async broadcast<
    T extends SwarmMessageTypesWithPayload,
    D extends SwarmMessageData<T>
  >(type: T, data: D): Promise<void>;

  public async broadcast<
    T extends SwarmMessageTypesWithoutPayload,
  >(type: T, data?: never): Promise<void>;

  public async broadcast<
    T extends SwarmMessageTypesWithPayload,
    D extends SwarmMessageData<T>
  >(type: T, data: D): Promise<void> {
    return this.channel.postMessage({
      from: this.id,
      type: type,
      data: data ? data as any : undefined,
    });
  }

  public async send<
    T extends SwarmMessageTypesWithPayload,
    D extends SwarmMessageData<T>
  >(to: string, type: T, data: D): Promise<void>;

  public async send<
    T extends SwarmMessageTypesWithoutPayload,
  >(to: string, type: T, data?: never): Promise<void>;

  public async send<
    T extends SwarmMessageTypesWithPayload,
    D extends SwarmMessageData<T>
  >(to: string, type: T, data: D): Promise<void> {
    return this.channel.postMessage({
      from: this.id,
      to: to,
      type: type,
      data: data ? data as any : undefined,
    });
  }

  private onMessage(message: SwarmMessage): void {
    switch (message.type) {
      case 'member.new':
        this.membersSubject.set(message.from);
        if (this.elector.isLeader) {
          this.send(message.from, 'member.leader').then(() => {
            this.logger.debug('Sent leader to new member');
          });
        }
        else {
          this.send(message.from, 'member.handshake').then(() => {
            this.logger.debug('Sent handshake to new member');
          });
        }
        break;
      case 'member.handshake':
        this.membersSubject.set(message.from);
        break;
      case 'member.leader':
        this.membersSubject.setLeader(message.from);
        this.leaderSubject.next(false);
        break;
      case 'member.remove':
        this.membersSubject.delete(message.from);
        break;
    }
  }

  private async close(): Promise<void> {
    await this.broadcast('member.remove');
    await this.channel.close();
  }

}
