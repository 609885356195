import moment from 'moment-timezone';
import { Duration, Moment } from 'moment';

export type IPeriodDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export interface IPeriodTime {
  hours: number;
  minutes: number;
}

export interface IHappyHoursPeriodRaw {
  id: number;
  startDay: IPeriodDay;
  startTime: string;
  endDay: IPeriodDay;
  endTime: string;
}

export interface IHappyHoursPeriod {
  id: number;
  startDay: IPeriodDay;
  startTime: IPeriodTime;
  endDay: IPeriodDay;
  endTime: IPeriodTime;
}

export class HappyHoursPeriodModel implements IHappyHoursPeriod {
  public readonly id: number;
  public readonly startDay: IPeriodDay;
  public readonly startTime: IPeriodTime;
  public readonly endDay: IPeriodDay;
  public readonly endTime: IPeriodTime;

  constructor(period: IHappyHoursPeriod) {
    this.id = period.id;
    this.startDay = period.startDay;
    this.startTime = period.startTime;
    this.endDay = period.endDay;
    this.endTime = period.endTime;
  }

  public get timeStart(): Moment {
    return HappyHoursPeriodModel.hhTimeToDate(this.startTime);
  }

  public get timeEnd(): Moment {
    return HappyHoursPeriodModel.hhTimeToDate(this.endTime);
  }

  public get timeLeftBeforeStart(): Duration {
    const currentTime = moment();
    const currentDay = currentTime.isoWeekday();
    const startMoment = this.timeStart;

    if (this.startDay > currentDay || (
      this.startDay === currentDay && startMoment.isAfter(currentTime)
    )) {
      startMoment.isoWeekday(this.startDay);
      return moment.duration(startMoment.diff(currentTime));
    } else {
      startMoment.isoWeekday(this.startDay + 7);
      return moment.duration(startMoment.diff(currentTime));
    }
  }

  public get timeLeftBeforeEnd(): Duration {
    const now = moment();
    const currentDay = now.isoWeekday();
    const endMoment = this.timeEnd;

    if (this.endDay > currentDay || (
      this.endDay === currentDay && endMoment.isAfter(now)
    )) {
      endMoment.isoWeekday(this.endDay);
      return moment.duration(endMoment.diff(now));
    } else {
      endMoment.isoWeekday(this.endDay + 7);
      return moment.duration(endMoment.diff(now));
    }
  }

  public static parseRaw(raw: IHappyHoursPeriodRaw): IHappyHoursPeriod {
    const timeStart = moment(raw.startTime, 'HH:mm:ss').isoWeekday(raw.startDay);
    const timeEnd = moment(raw.endTime, 'HH:mm:ss').isoWeekday(raw.endDay);

    return new HappyHoursPeriodModel({
      id: raw.id,
      startDay: raw.startDay,
      startTime: {
        hours: timeStart.hours(),
        minutes: timeStart.minutes(),
      },
      endDay: raw.endDay,
      endTime: {
        hours: timeEnd.hours(),
        minutes: timeEnd.minutes(),
      },
    });
  }

  public static hhTimeToDate(time: IPeriodTime): Moment {
    return moment().set({
      hours: time.hours,
      minutes: time.minutes,
      seconds: 0,
    });
  }

}
