import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import {
  ActivitySectionDirective,
  HappyHoursPeriodNextModel,
  HappyHoursPeriodNowModel,
  HappyHoursService,
  UserActivityClickTrackingDirective,
} from '@core';

@Component({
  selector: 'app-happy-hours',
  templateUrl: './happy-hours.component.html',
  imports: [
    TranslateModule,
    RouterLink,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./happy-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HappyHoursComponent {

  private readonly status$ = this.happyHoursService.status$.pipe(
    map((hh) => {
      if (!(
        hh instanceof HappyHoursPeriodNowModel
      ) && !(
        hh instanceof HappyHoursPeriodNextModel && hh.timeLeft.asMinutes() <= 30
      )) {
        return {
          now: false,
          show: false,
          active: false,
          timeLeft: '',
          activeCountdownTime: '',
        };
      }

      const isNowHappyHours = hh instanceof HappyHoursPeriodNowModel;
      const timeLeft = hh.timeLeft;

      const showTimer = (
        timeLeft.seconds() >= 55 ||
        timeLeft.seconds() === 0 ||
        timeLeft.asMinutes() <= 1
      );

      const countDown = [
        (
          '0' + timeLeft.minutes()
        ).slice(-2),
        (
          '0' + timeLeft.seconds()
        ).slice(-2),
      ].join(':');

      return {
        now: isNowHappyHours,
        show: showTimer,
        active: Boolean(timeLeft),
        timeLeft: countDown,
        activeCountdownTime: countDown,
      };
    }),
  );

  public readonly status = toSignal(this.status$, {
    requireSync: true,
  });

  constructor(
    private readonly happyHoursService: HappyHoursService,
  ) {}

}
