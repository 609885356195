import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { IFileCache } from '../models';
import { Repository } from '../database/repository';

@Injectable({
  providedIn: 'root',
})
export class FileCacheRepository extends Repository<IFileCache, 'url'> {

  constructor(db: NgxIndexedDBService) {
    super(db, 'fileCache', 'url');
  }

}
