import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { IHappyHoursPeriod } from '../models';
import { Repository } from '../database/repository';

@Injectable({
  providedIn: 'root',
})
export class HappyHoursRepository extends Repository<IHappyHoursPeriod, 'id'> {

  constructor(db: NgxIndexedDBService) {
    super(db, 'happyHoursPeriod', 'id');
  }

}
