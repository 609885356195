export const isUndefined = <T>(value: T | undefined): value is undefined => value === undefined;

export const isDefined = <T>(value: T | undefined): value is T => !isUndefined(value);

export const isNull = <T>(value: T | null): value is null => value === null;

export const isNotNull = <T>(value: T | null): value is T => !isNull(value);

export const isBoolean = <T>(value: T | boolean): value is boolean => typeof value === 'boolean';

export const isString = <T>(value: T | string): value is string => typeof value === 'string';

export const isSymbol = <T>(value: T | symbol): value is symbol => typeof value === 'symbol';

export const isNumber = <T>(value: T | number): value is number => typeof value === 'number';

export const isDate = <T>(value: T | Date): value is Date => value instanceof Date;

export const isObject = <T>(value: T | object): value is object => typeof value === 'object';

export const isArray = <T>(value: T | unknown[]): value is unknown[] => Array.isArray(value);

export const isFunction = <T>(value: T | Function): value is Function => typeof value === 'function';

export const isPromise = <T>(value: T | Promise<T>): value is Promise<T> => value instanceof Promise;

export const isTruthy = <T>(value: T | unknown): value is T => Boolean(value);

export const isFalsy = <T>(value: T | unknown): value is unknown => !Boolean(value);
