import { DestroyRef, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, of, timer } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { WidgetUiConfigRef } from './widget-ui-config.ref';
import { AuthService } from './auth.service';
import { WatchdogService } from './watchdog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class InteractionService {

  private readonly logger = this.watchdog.tag('Interaction', 'blue');

  public readonly idle$ = new BehaviorSubject<boolean>(true);
  public readonly status$ = this.idle$.pipe(
    map((idle) => !idle),
  );
  public readonly idleCount$ = new BehaviorSubject<number>(0);
  public readonly lastInteractionAt$ = new BehaviorSubject<Date>(new Date());
  public readonly lastStartInteractionAt$ = new BehaviorSubject<Date>(new Date());
  public readonly lastStartIdleAt$ = new BehaviorSubject<Date | null>(null);

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly auth: AuthService,
    private readonly watchdog: WatchdogService,
    private readonly widgetUiConfig: WidgetUiConfigRef,
  ) {}

  public get idle(): boolean {
    return this.idle$.getValue();
  }

  public get interacting(): boolean {
    return !this.idle;
  }

  public get lastInteractionAt(): Date {
    return this.lastInteractionAt$.getValue();
  }

  public get lastStartInteractionAt(): Date {
    return this.lastStartInteractionAt$.getValue();
  }

  public get lastStartIdleAt(): Date | null {
    return this.lastStartIdleAt$.getValue();
  }

  public get idleCount(): number {
    return this.idleCount$.getValue();
  }

  public get idleDelay(): number {
    if (this.widgetUiConfig.idleDelay && this.widgetUiConfig.idleDelay > 0) {
      return this.widgetUiConfig.idleDelay * 1000;
    }
    else {
      return 0;
    }
  }

  public initialize(): void {
    this.logger.info('Initializing');

    merge(
      of('start'),
      fromEvent(document, 'click'),
      fromEvent(document, 'scroll'),
      fromEvent(document, 'touchstart'),
      this.auth.logined$,
    ).pipe(
      tap(() => {
        this.lastInteractionAt$.next(new Date());

        if (this.idle) {
          this.logger.info('User activity detected');
          this.idle$.next(false);
          this.lastStartInteractionAt$.next(new Date());
        }
      }),
      // @TODO: Remove filter and move disable idle to uses of IdleService
      filter(() => this.idleDelay !== 0),
      switchMap(() => timer(this.idleDelay)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      if (this.interacting) {
        this.logger.info('Idle detected');
        this.idle$.next(true);
        this.lastStartIdleAt$.next(new Date());
        this.idleCount$.next(this.idleCount + 1);
      }
    });
  }

}
