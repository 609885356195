<div class="backdrop"></div>
<div class="box">
  <h1>OUR HOUSE</h1>

  <div class="buttons" coreActivitySection="СallWaiterButtons">
    @if (withCallWaiterMain()) {
      <button
        [class.active]="callWaiterMain()"
        [disabled]="callWaiterAnotherRound() || callWaiterRequestBill()"
        (click)="!callWaiterAnotherRound() && !callWaiterRequestBill() && handlerCallWaiter($event)"
        [coreUserActivityClickTracking]=" callWaiterMain() ? 'CancelCallWaiter' : 'CallWaiter'"
      >
        <img src="/images/hand.svg" alt=""/>
        <div class="content">
          <div class="title">EXCUSE ME - EH</div>
          <div class="description">
            @if (callWaiterMain()) {
              Your server has been alerted
            } @else {
              Tap to call your server. <br/>We'll be with you shortly!
            }
          </div>
        </div>
      </button>
    }

    @if (withCallWaiterAnotherRound()) {
      <button
        [class.active]="callWaiterAnotherRound()"
        [disabled]="callWaiterMain() || callWaiterRequestBill()"
        (click)="!callWaiterMain() && !callWaiterRequestBill() && handlerCallWaiterToRepeat($event)"
        [coreUserActivityClickTracking]=" callWaiterAnotherRound() ? 'CancelCallWaiterToRepeat' : 'CallWaiterToRepeat'"
      >
        <img src="/images/wine.svg" alt=""/>
        <div class="content">
          <div class="title">ANOTHER ROUND</div>
          <div class="description">
            @if (callWaiterAnotherRound()) {
              Your drink is on its way
            } @else {
              Tap to order another <br/>round of drinks.
            }
          </div>
        </div>
      </button>
    }

    @if (withCallWaiterRequestBill()) {
      <button
        [class.active]="callWaiterRequestBill()"
        [disabled]="callWaiterMain() || callWaiterAnotherRound()"
        (click)="!callWaiterMain() && !callWaiterAnotherRound() && handlerCallWaiterToPay($event)"
        [coreUserActivityClickTracking]=" callWaiterRequestBill() ? 'CancelCallWaiterToPay' : 'CallWaiterToPay'"
      >
        <img src="/images/bill.svg" alt=""/>
        <div class="content">
          <div class="title">REQUEST BILL</div>
          <div class="description">
            @if (callWaiterRequestBill()) {
              We will prepare your bill <br/>as soon as possible
            } @else {
              Tap to notify your server <br/>and pay your bill
            }
          </div>
        </div>
      </button>
    }
  </div>
</div>
