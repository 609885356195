import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ISpecialRaw } from '../models';
import { Repository } from '../database/repository';

@Injectable({
  providedIn: 'root',
})
export class SpecialsRepository extends Repository<ISpecialRaw, 'id'> {

  constructor(db: NgxIndexedDBService) {
    super(db, 'specials', 'id');
  }



}
