<div class="content" #content>
  <div class="slider">
    @for (slide of slides; track slide; let i = $index) {
      @if (i === index || i === index - 1 || i === index + 1 || (i === 0 && this.index === this.slides.length - 1) ||
      (i === this.slides.length - 1 && this.index === 0)) {
        <img
          class="slide"
          [src]="slide"
          [class.previous]="i === index - 1 || (i === this.slides.length - 1 && this.index === 0)"
          [class.next]="i === index + 1 || (i === 0 && this.index === this.slides.length - 1)"
          [@slideAnimation]="i === index ? 'active' : (i < index ? 'previous' : 'next')"
          alt="slide"
        />
      }
    }
  </div>
</div>
