import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivitySectionDirective, CallWaiterService, UserActivityClickTrackingDirective } from '@core';

@Component({
  selector: 'app-service-centre',
  templateUrl: './service-centre.component.html',
  imports: [
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
  ],
  styleUrls: ['./service-centre.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCentreComponent {

  public readonly withCallWaiterMain = toSignal(this.callWaiter.withMain$);
  public readonly withCallWaiterRequestBill = toSignal(this.callWaiter.withRequestBill$);
  public readonly withCallWaiterAnotherRound = toSignal(this.callWaiter.withAnotherRound$);

  public readonly callWaiterMain = toSignal(this.callWaiter.mainStatus$, {
    requireSync: true,
  });

  public readonly callWaiterRequestBill = toSignal(this.callWaiter.requestBillStatus$, {
    requireSync: true,
  });

  public readonly callWaiterAnotherRound = toSignal(this.callWaiter.anotherRoundStatus$, {
    requireSync: true,
  });

  constructor(
    private readonly callWaiter: CallWaiterService,
  ) {}

  public handlerCallWaiter($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      $event.preventDefault();
      this.callWaiter.setMain(!this.callWaiterMain());
    }
  }

  public handlerCallWaiterToPay($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      $event.preventDefault();
      this.callWaiter.setRequestBill(!this.callWaiterRequestBill());
    }
  }

  public handlerCallWaiterToRepeat($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      $event.preventDefault();
      this.callWaiter.setAnotherRound(!this.callWaiterAnotherRound());
    }
  }

}
