import { isFunction } from './is';
import { RGBA } from 'color-blend/dist/types';

export const noop = () => {};

export const value = <T>(value: T | (() => T)): T => {
  return isFunction(value) ? value() : value;
};

export const hexToRgba = (hex: string): RGBA => {
  return {
    r: parseInt(hex.slice(1, 3), 16),
    g: parseInt(hex.slice(3, 5), 16),
    b: parseInt(hex.slice(5, 7), 16),
    a: 1,
  };
};

export const adjustColorLightness = (color: string, amount: number): string => {
  const colorHex = color.replace(/[^0-9a-f]/gi, '');
  const rgb = parseInt(colorHex, 16);
  const r = Math.min(
    255,
    Math.max(
      0,
      (
        (
          rgb >> 16
        ) & 0xff
      ) + amount,
    ),
  );
  const g = Math.min(
    255,
    Math.max(
      0,
      (
        (
          rgb >> 8
        ) & 0xff
      ) + amount,
    ),
  );
  const b = Math.min(
    255,
    Math.max(
      0,
      (
        rgb & 0xff
      ) + amount,
    ),
  );
  return '#' + (
    b | (
      g << 8
    ) | (
      r << 16
    )
  ).toString(16).padStart(6, '0');
};
