import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { isString } from '../utils';
import { WatchdogService } from './watchdog.service';

@Injectable()
export class FileDownloaderService {

  private readonly logger = this.watchdog.tag('File Downloader', 'blue');

  constructor(
    private readonly http: HttpClient,
    private readonly watchdog: WatchdogService,
  ) {}

  public downloadAsBlob(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob',
      headers: {
        'cache-control': 'no-cache, no-store, must-revalidate',
      },
    }).pipe(
      tap({
        next: () => this.logger.debug('File downloaded', url),
        error: (error) => this.logger.error('Failed to download file', url, error),
      }),
    );
  }

  public downloadAsBase64(url: string): Observable<string> {
    return this.downloadAsBlob(url).pipe(
      switchMap((blob) => this.blobToBase64(blob)),
    );
  }

  public blobToBase64(blob: Blob): Observable<string> {
    const fileReader = new FileReader();

    const observable = new Observable<string>((observer) => {
      fileReader.onerror = (event) => {
        observer.error(event);
      };

      fileReader.onloadend = () => {
        if (!isString(fileReader.result)) {
          observer.error('Failed to convert blob to base64');
          return;
        }

        observer.next(fileReader.result);
        observer.complete();
      };

      return () => fileReader.abort();
    });

    fileReader.readAsDataURL(blob);

    return observable.pipe(
      tap({
        next: () => this.logger.debug('Blob converted to base64'),
        error: (error) => this.logger.error('Failed to convert blob to base64', error),
      }),
    );
  }

}
