<ng-lottie
  [options]="animationOptions"
  (animationCreated)="onAnimationCreated($event)"
  (complete)="onAnimationComplete()"
/>

<div class="bar">
  <div class="text">Touch the screen to interact</div>
  <div class="line"></div>
</div>
