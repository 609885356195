@let activeTab = open();

<div class="controls">
  @if (swarmMembersCount() > 1) {
    <button class="toggle" (click)="handleToggleSwarm($event)" [class.active]="activeTab === 'swarm'">
      Screens
      @if (swarmLeader()) {
        <span style="color: #2df702;">Leader</span>
      } @else {
        <span style="color: #d0b57b;">Member</span>
      }
    </button>
  }

  @if (table(); as table) {
    <button class="toggle" (click)="handleToggleTable($event)" [class.active]="activeTab === 'table'">
      Table {{ table.tableName }}
    </button>
  }

  <button class="toggle" (click)="handleToggleApp($event)" [class.active]="activeTab === 'app'">
    {{ appName }} | {{ appEnv }} | v{{ appVersion }}
  </button>

  <button class="red" (click)="handleReload($event)">
    Restart
  </button>

  @if (updateEnabled) {
    @if (updateStatus(); as updateStatus) {
      @if (updateStatus.state === 'ready') {
        <button class="yellow" (click)="handleUpdate($event)">
          Update {{ updateStatus.latestBuild.version === appVersion ? 'to latest' : 'to v' + updateStatus.latestBuild.version }}
        </button>
      } @else {
        <button class="green" (click)="handleCheckForUpdate($event)">
          Check for updates
        </button>
      }
    } @else {
      <button class="green" (click)="handleCheckForUpdate($event)">
        Check for updates
      </button>
    }
  }
</div>

@switch (activeTab) {
  @case ('swarm') {
    <div class="content">
      <core-debug-ui-card label="Screens">
        <core-debug-swarm/>
      </core-debug-ui-card>
    </div>
  }
  @case ('app') {
    <div class="content">
      <core-debug-ui-card label="App Information">
        <core-debug-app/>
      </core-debug-ui-card>
      <core-debug-ui-card label="Device Information">
        <core-debug-device/>
      </core-debug-ui-card>
      <core-debug-ui-card label="Performance Monitoring">
        <core-debug-performance/>
      </core-debug-ui-card>
    </div>
  }
  @case ('table') {
    <div class="content">
      <core-debug-ui-card label="Table Information">
        <core-debug-table/>
      </core-debug-ui-card>
    </div>
  }
}
