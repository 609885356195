import { DestroyRef, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, fromEvent, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { isDefined, isString } from '../utils';
import { IDWallMedia } from '../models';
import { WatchdogService } from './watchdog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type IntercomMethod =
  | 'init'
  | 'device.turnOff'
  | 'device.work_schedule'
  | 'playlist.get_items'
  | 'playlist.get_playing_now'
  | 'playlist.stop'
  | 'playlist.resume'
  | 'ambient_light.on'
  | 'ambient_light.off'
  | 'battery.level.get'
  | 'brightness.get'
  | 'brightness.set'
  | 'demo.method';
type IntercomMethodData = any;

type IntercomEvent =
  | 'device.work_schedule.failed'
  | 'playlist.items'
  | 'playlist.playing_now'
  | 'playlist.playing_finished'
  | 'battery.level'
  | 'brightness.level'
  | 'demo.mode'
  | 'demo.events';
type IntercomEventData = any;

export interface IIntercomEventMessage {
  type: 'dwall-events';
  version: string;
  event: IntercomEvent;
  data: IntercomEventData;
}

export interface IIntercomMethodMessage {
  type: 'dwall-events';
  version: string;
  method: IntercomMethod;
  data: IntercomMethodData;
}

export interface BatteryLevel {
  level: number;
  charging: boolean;
}

@Injectable()
export class Intercom {
  private readonly logger = this.watchdog.tag('Intercom', 'cyan');

  private readonly portSubject = new BehaviorSubject<MessagePort | null>(null);
  private readonly connectionCountSubject = new BehaviorSubject<number>(0);
  private readonly lastConnectionAtSubject = new BehaviorSubject<Date | null>(null);
  private readonly messageSubject = new Subject<IIntercomEventMessage>();
  private readonly batteryLevelSubject = new BehaviorSubject<BatteryLevel | null>(null);
  private readonly currentMediaSubject = new BehaviorSubject<IDWallMedia | null>(null);

  public readonly connected$ = this.portSubject.pipe(
    map((port) => !!port),
  );

  public readonly connectionCount$ = this.connectionCountSubject.asObservable();
  public readonly lastConnectionAt$ = this.lastConnectionAtSubject.asObservable();

  public readonly messages$ = this.messageSubject.asObservable();

  public readonly currentMedia$ = this.currentMediaSubject.asObservable();
  public readonly batteryLevel$ = this.batteryLevelSubject.asObservable();

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly watchdog: WatchdogService,
  ) {}

  private static methodMessage(method: IntercomMethod, data: IntercomMethodData = null): IIntercomMethodMessage {
    return {
      type: 'dwall-events',
      version: '0.0.1',
      method,
      data,
    };
  }

  public initialize(): void {
    this.logger.info('Initialize');

    // Handshake
    fromEvent<MessageEvent>(window, 'message').pipe(
      filter((event) => {
        return event.data === 'dwall-handshake' && isDefined(event.ports) && isDefined(event.ports[0]);
      }),
      tap((event) => this.logger.debug('Handshake', JSON.stringify(event))),
      map((event) => event.ports[0]),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((port) => {
      this.portSubject.next(port);
      this.connectionCountSubject.next(this.connectionCountSubject.value + 1);
      this.lastConnectionAtSubject.next(new Date());
    });

    // Message handling
    this.portSubject.asObservable().pipe(
      switchMap((port) => {
        if (!port) {
          return EMPTY;
        }

        port.start();

        return fromEvent<MessageEvent>(port, 'message');
      }),
      map((event) => {
        // TODO: Remove this hack when Android will be fixed
        if (isString(event.data)) {
          // @eslint-disable-next-line
          return JSON.parse(event.data.replace('\/', ''));
        }

        return event.data;
      }),
      filter((message) => {
        return message.type && message.version && message.event;
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((message) => {
      this.messageSubject.next(message);
    });

    // Current media playing
    this.messages$.pipe(
      filter((message) => message.event === 'playlist.playing_now'),
      map((message) => message.data as IDWallMedia),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((data) => {
      this.currentMediaSubject.next(data);
    });

    // Battery level updates
    this.messages$.pipe(
      filter((message) => message.event === 'battery.level'),
      map((message) => message.data as BatteryLevel),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((status) => {
      this.batteryLevelSubject.next(status);
    });
  }

  public call(method: IntercomMethod, data: IntercomMethodData = null): boolean {
    const port = this.portSubject.getValue();
    if (!port) {
      return false;
    }

    const message = Intercom.methodMessage(method, data);
    // TODO: Remove this hack when Android will be fixed
    port.postMessage(JSON.stringify(message));
    this.logger.debug('Sent:', message);

    return true;
  }

}
