import { Directive, Inject, input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CORE_USER_ACTIVITY_SECTION } from '../services';

@Directive({
  selector: '[coreActivitySection]',
  standalone: true,
  providers: [
    {
      provide: CORE_USER_ACTIVITY_SECTION,
      useFactory: () => new BehaviorSubject<string>('unknown'),
    },
  ],
})
export class ActivitySectionDirective implements OnInit {

  public readonly section = input.required<string>({
    alias: 'coreActivitySection',
  });

  constructor(
    @Inject(CORE_USER_ACTIVITY_SECTION) private sectionSubject: BehaviorSubject<string>,
  ) {}

  public ngOnInit(): void {
    this.sectionSubject.next(this.section());
  }

}
