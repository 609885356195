import moment from 'moment-timezone';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { interval, merge, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import {
  CORE_APP_ENV,
  CORE_APP_NAME,
  CORE_APP_VERSION,
  CORE_STARTUP_DATE,
  CORE_STARTUP_ID,
} from '../../../../../core.tokens';
import { DebugFieldComponent, DebugItemsComponent, DebugSizeComponent } from '../../ui';
import {
  CallWaiterService,
  InteractionService,
  Intercom,
  NetworkService,
  UpdateService,
  WebsocketService,
} from '../../../../services';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'core-debug-app',
  templateUrl: './debug-app.component.html',
  styleUrls: ['./debug-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DebugItemsComponent,
    DebugFieldComponent,
    DebugSizeComponent,
    DatePipe,
  ]
})
export class DebugAppComponent {

  private readonly date$ = merge(
    of(0),
    interval(5_000),
  ).pipe(
    map(() => moment().format('YYYY-MM-DD HH:mm')),
    distinctUntilChanged(),
  );

  private readonly uptime$ = merge(
    of(0),
    interval(5_000),
  ).pipe(
    map(() => moment.duration(moment().diff(moment(this.appStartupDate))).humanize()),
    distinctUntilChanged(),
  );

  public readonly date = toSignal(this.date$);
  public readonly uptime = toSignal(this.uptime$);

  public readonly callWaiterMainStatus = toSignal(this.callWaiter.mainStatus$);
  public readonly callWaiterMainCount = toSignal(this.callWaiter.mainCount$);
  public readonly callWaiterMainLastAt = toSignal(this.callWaiter.mainLastCallAt$);
  public readonly callWaiterRequestBillStatus = toSignal(this.callWaiter.requestBillStatus$);
  public readonly callWaiterRequestBillCount = toSignal(this.callWaiter.requestBillCount$);
  public readonly callWaiterRequestBillLastAt = toSignal(this.callWaiter.requestBillLastCallAt$);
  public readonly callWaiterAnotherRoundStatus = toSignal(this.callWaiter.anotherRoundStatus$);
  public readonly callWaiterAnotherRoundCount = toSignal(this.callWaiter.anotherRoundCount$);
  public readonly callWaiterAnotherRoundLastAt = toSignal(this.callWaiter.anotherRoundLastCallAt$);

  public readonly updateEnabled = this.update.enabled;
  public readonly updateStatus = toSignal(this.update.status$);
  public readonly updateCheckStrategyTitle = this.update.updateCheckStrategyTitle;
  public readonly updateActivateStrategyTitle = this.update.updateActivateStrategyTitle;

  public readonly interactionStatus = toSignal(this.interaction.idle$);
  public readonly interactionLastInteractionAt = toSignal(this.interaction.lastInteractionAt$);
  public readonly interactionLastStartInteractionAt = toSignal(this.interaction.lastStartInteractionAt$);
  public readonly interactionLastStartIdleAt = toSignal(this.interaction.lastStartIdleAt$);
  public readonly interactionIdleDelay = this.interaction.idleDelay;

  public readonly networkStatus = toSignal(this.network.status$);
  public readonly networkConnectionCount = toSignal(this.network.connectionCount$);
  public readonly networkLastConnectionAt = toSignal(this.network.lastConnectionAt$);
  public readonly networkLastDisconnectionAt = toSignal(this.network.lastDisconnectionAt$);

  public readonly websocketStatus = toSignal(this.websocket.status$);
  public readonly websocketConnectionCount = toSignal(this.websocket.connectionCount$);
  public readonly websocketLastConnectionAt = toSignal(this.websocket.lastConnectionAt$);
  public readonly websocketLastDisconnectionAt = toSignal(this.websocket.lastDisconnectionAt$);

  public readonly intercomConnected = toSignal(this.intercom.connected$);
  public readonly intercomConnectionCount = toSignal(this.intercom.connectionCount$);
  public readonly intercomLastConnectionAt = toSignal(this.intercom.lastConnectionAt$);

  constructor(
    @Inject(CORE_STARTUP_ID) public readonly appStartupId: string,
    @Inject(CORE_STARTUP_DATE) public readonly appStartupDate: string,
    @Inject(CORE_APP_NAME) public readonly appName: string,
    @Inject(CORE_APP_ENV) public readonly appEnv: string,
    @Inject(CORE_APP_VERSION) public readonly appVersion: string,
    private readonly update: UpdateService,
    private readonly interaction: InteractionService,
    private readonly network: NetworkService,
    private readonly websocket: WebsocketService,
    private readonly intercom: Intercom,
    private readonly callWaiter: CallWaiterService,
  ) {}

}
