@let isHomePage = pageIs().home;
@let isMenuPage = pageIs().menu;
@let isMorePage = pageIs().more;
@let isStorePage = pageIs().store;
@let isRewardedPage = pageIs().rewarded;

<div
  class="tab-bar tab-bar__with-bg"
  [class.hide]="hideTabBar()"
>
  <div class="tabs" coreActivitySection="TabBar">
    <div class="tabs__side left">
      <button
        class="tab"
        [class.underline]="isMenuPage"
        [routerLink]="isMenuPage ? '/main/home' : '/main/menu'"
        routerLinkActive="active"
        [coreUserActivityClickTracking]="isMenuPage ? 'MenuClose' : 'MenuOpen'"
      >
        <div class="icon">
          <img
            [src]="isMenuPage
              ? '/images/menu_selected.svg'
              : '/images/menu_enabled.svg'"
            alt=""
          />
        </div>
        <div class="label" [class.active]="isMenuPage">
          <span>{{ 'tabBar.menu' | translate }}</span>
        </div>
      </button>

      <button
        class="tab"
        [class.underline]="isMorePage"
        [routerLink]="isMorePage ? '/main/home' : '/main/more'"
        routerLinkActive="active"
        [coreUserActivityClickTracking]="isMorePage ? 'MoreClose' : 'MoreOpen'"
      >
        <div class="icon">
          <img
            [src]="isMorePage
              ? '/images/new_selected.svg'
              : '/images/new_enabled.svg'"
            alt=""
          />
        </div>
        <div class="label" [class.active]="isMorePage">
          <span>{{ 'tabBar.more' | translate }}</span>
        </div>
      </button>
    </div>

    <button
      [class.show]="isHomePage"
      class="tab logo"
      routerLink="/main/home/service"
      routerLinkActive="active"
      coreUserActivityClickTracking="ServicePageOpen"
    >
      <img class="logoImg" src="/images/logo.svg" alt=""/>
    </button>

    <button
      [class.show]="!isHomePage"
      class="tab logo"
      routerLink="/main/home"
      routerLinkActive="active"
      coreUserActivityClickTracking="PageClose"
    >
      <img class="logoImg" src="/images/logo_clear.svg" alt=""/>
    </button>

    <div class="tabs__side right">
      <button
        class="tab"
        [class.underline]="isStorePage"
        [routerLink]="isStorePage ? '/main/home' : '/main/store'"
        routerLinkActive="active"
        [coreUserActivityClickTracking]="isStorePage ? 'StorePageClose' : 'StorePageOpen'"
      >
        <div class="icon">
          <img
            [src]="isStorePage
              ? '/images/store_selected.svg'
              : '/images/store_enabled.svg'"
            alt=""
          />
        </div>
        <div class="label" [class.active]="isStorePage">
          <span>{{ 'tabBar.store' | translate }}</span>
        </div>
      </button>

      <button
        class="tab"
        [class.underline]="isRewardedPage"
        [routerLink]="isRewardedPage ? '/main/home' : '/main/rewarded'"
        routerLinkActive="active"
        [coreUserActivityClickTracking]="isRewardedPage ? 'GetRewardedPageClose' : 'GetRewardedPageOpen'"
      >
        <div class="icon">
          <img
            [src]="isRewardedPage
              ? '/images/rewards_selected.svg'
              : '/images/rewards_enabled.svg'"
            alt=""
          />
        </div>
        <div class="label" [class.active]="isRewardedPage">
          <span>{{ 'tabBar.rewards' | translate }}</span>
        </div>
      </button>
    </div>
  </div>
</div>

@if (hideTabBar()) {
  <core-interacts-present/>
}
