import moment from 'moment-timezone';
import { DestroyRef, Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap, toArray } from 'rxjs/operators';
import { isDefined } from '../utils';
import {
  HappyHoursPeriodModel,
  HappyHoursPeriodNextModel,
  HappyHoursPeriodNowModel,
  IHappyHoursPeriod,
  IHappyHoursPeriodRaw,
} from '../models';
import { AuthService } from './auth.service';
import { WatchdogService } from './watchdog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WebsocketService } from './websocket.service';
import { HappyHoursRepository } from '../repositories/happy-hours.repository';
import { SwarmService } from './swarm.service';

@Injectable()
export class HappyHoursService {

  private readonly logger = this.watchdog.tag('Happy Hours', 'green');

  public readonly happyHours$ = this.hhRepository.all$().pipe(
    map((entries) => entries.map((entry) => new HappyHoursPeriodModel(entry))),
    tap({
      next: (entries) => this.logger.debug('Happy hours all loaded', entries),
      error: (error) => this.logger.error('Failed to load all happy hours', error),
    }),
  );

  public readonly status$ = new BehaviorSubject<HappyHoursPeriodNextModel | HappyHoursPeriodNowModel | null>(null);
  public readonly isNow$ = this.status$.pipe(
    map((hh) => hh instanceof HappyHoursPeriodNowModel),
    distinctUntilChanged(),
  );

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly auth: AuthService,
    private readonly watchdog: WatchdogService,
    private readonly swarm: SwarmService,
    private readonly hhRepository: HappyHoursRepository,
    private readonly webSocket: WebsocketService,
  ) {}

  public initialize(): void {
    this.logger.info('Initialized');

    this.swarm.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.auth.logouted$),
      switchMap(() => this.hhRepository.clear$()),
      tap(() => this.logger.info('Cleared happy hours on logout')),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.swarm.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.webSocket.messages$),
      filter((response) => response.type === 'tableInfo'),
      switchMap((response) => {
        if (response.data.happyMenuPeriods) {
          return this.updateByCollection(response.data.happyMenuPeriods);
        } else {
          return this.hhRepository.clear$();
        }
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.happyHours$.pipe(
      tap(() => this.logger.info('Happy hours started to watch')),
      switchMap((periods) => {
        if (periods.length === 0) {
          return of(null);
        }

        return this.currentPeriod(periods);
      }),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((status) => {
      this.logger.debug('Happy hours status changed', status ? status : 'No happy hours now');
      this.status$.next(status);
    });
  }

  public updateByCollection(timetable: IHappyHoursPeriodRaw[]): Observable<IHappyHoursPeriod[]> {
    return this.happyHours$.pipe(
      take(1),
      filter((value) => {
        const newValue = timetable.map(HappyHoursPeriodModel.parseRaw);

        return JSON.stringify(value) !== JSON.stringify(newValue);
      }),
      switchMap(() => this.hhRepository.clear$()),
      switchMap(() =>
        concat(
          ...timetable.map((hh) =>
            this.hhRepository.add$(HappyHoursPeriodModel.parseRaw(hh)).pipe(
              filter(isDefined),
            ),
          ),
        ).pipe(
          toArray(),
        ),
      ),
    );
  }

  public currentPeriod(values: IHappyHoursPeriod[]): Observable<HappyHoursPeriodNextModel | HappyHoursPeriodNowModel | null> {
    const periods = values.map((period) => new HappyHoursPeriodModel(period));

    return timer(0, 1000).pipe(
      map(() => {
        const currentTime = moment();
        const currentDay = currentTime.isoWeekday(); // 1 - Monday ... 7 - Sunday

        const activeNow = periods.filter(model => {
          const startDay = model.startDay; // 1 - Monday ... 7 - Sunday
          const endDay = model.endDay; // 1 - Monday ... 7 - Sunday

          if (endDay < startDay) {
            return currentDay >= startDay || currentDay <= endDay;
          }

          return currentDay >= startDay && currentDay <= endDay;
        }).find((time) => {
          const startDay = time.startDay;
          const startTime = time.timeStart.clone();
          const endDay = time.endDay;
          const endTime = time.timeEnd;

          if (currentDay === startDay && currentTime <= startTime) {
            return false
          }
          else if (currentDay === endDay && currentTime >= endTime) {
            return false;
          }

          return true;
        });

        if (activeNow) {
          return new HappyHoursPeriodNowModel(activeNow);
        }

        const nextPeriod = periods
          .filter((model) => {
            const start = model.timeStart.clone().isoWeekday(model.startDay);
            return currentTime.isBefore(start);
          })
          .sort((a, b) => {
            const startA = a.timeStart.clone().isoWeekday(a.startDay);
            const startB = b.timeStart.clone().isoWeekday(b.startDay);
            return startA.diff(startB);
          })[0];

        if (nextPeriod) {
          return new HappyHoursPeriodNextModel(nextPeriod);
        }

        return null;
      }),
    );
  }

}
