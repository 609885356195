import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { CORE_DEVICE_LOCALE, CORE_DEVICE_NUMBERING_SYSTEM, CORE_DEVICE_TIMEZONE } from '../../../../../core.tokens';
import { DebugFieldComponent, DebugItemsComponent } from '../../ui';
import { fromEvent, map, merge, of } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'core-debug-device',
  templateUrl: './debug-device.component.html',
  styleUrls: ['./debug-device.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DebugItemsComponent,
    DebugFieldComponent,
    UpperCasePipe,
    TitleCasePipe,
  ]
})
export class DebugDeviceComponent {

  private readonly viewport$ = merge(
    of({
      width: window.innerWidth,
      height: window.innerHeight,
    }),
    fromEvent(window, 'resize').pipe(
      map(() => (
        {
          width: window.innerWidth,
          height: window.innerHeight,
        }
      )),
    ),
  );

  public readonly viewport = toSignal(this.viewport$);

  public readonly screen = window.screen;

  constructor(
    @Inject(CORE_DEVICE_TIMEZONE) public readonly deviceTimezone: string,
    @Inject(CORE_DEVICE_LOCALE) public readonly deviceLocale: string,
    @Inject(CORE_DEVICE_NUMBERING_SYSTEM) public readonly deviceNumberingSystem: string,
  ) {}

}
