import { Directive, HostListener, Inject, input, OnInit } from '@angular/core';
import { CORE_USER_ACTIVITY_SECTION, ScreenTrackingService, UserActivityService } from '../services';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Directive({
  selector: '[coreUserActivityClickTracking]',
  standalone: true,
})
export class UserActivityClickTrackingDirective implements OnInit {

  public readonly target = input.required<string>({
    alias: 'coreUserActivityClickTracking',
  });

  private section = '';
  private screen = '';

  constructor(
    @Inject(CORE_USER_ACTIVITY_SECTION) private sectionSubject: BehaviorSubject<string>,
    private readonly screenTracking: ScreenTrackingService,
    private readonly clickTracking: UserActivityService,
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.sectionSubject,
      this.screenTracking.screen$,
    ]).subscribe(([section, screen]) => {
      this.section = section;
      this.screen = screen;
    });
  }

  @HostListener('click', ['$event'])
  public clickEvent(event: MouseEvent): void {
    event.preventDefault();
    this.clickTracking.trackClick(event, this.screen, this.section, this.target());
  }

}
