import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Inject, viewChild } from '@angular/core';
import { CORE_DEVICE_LOCALE, CORE_DEVICE_NUMBERING_SYSTEM, CORE_DEVICE_TIMEZONE } from '../../../../../core.tokens';
import { DebugItemsComponent } from '../../ui';
import { animationFrames, map, switchMap } from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';

declare const performance: Performance & {
  memory: {
    usedJSHeapSize: number;
    totalJSHeapSize: number;
    jsHeapSizeLimit: number;
  } | undefined;
};

const Panel = (
  name: string,
  fg: string,
  bg: string,
  w: number = 180,
  h: number = 50,
) => {
  const pixelRatio = Math.round(window.devicePixelRatio || 1);

  const gap = 3 * pixelRatio;
  const fontSize = 14 * pixelRatio;

  const width = w * pixelRatio;
  const height = h * pixelRatio;
  const textX = gap;
  const textY = gap;
  const graphX = gap;
  const graphY = fontSize + (
    gap * 2
  );
  const graphWidth = width - (
    gap * 2
  );
  const graphHeight = height - graphY - gap;
  const barWidth = 4 * pixelRatio;

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  canvas.style.width = w + 'px';
  canvas.style.height = h + 'px';
  canvas.style.opacity = '0.8';
  canvas.style.borderRadius = '6px';

  const context = canvas.getContext('2d')!;
  context.font = 'bold ' + fontSize + 'px Helvetica,Arial,sans-serif';
  context.textBaseline = 'top';

  context.fillStyle = bg;
  context.fillRect(0, 0, width, height);

  context.fillStyle = fg;
  context.fillText(name, textX, textY);
  context.fillRect(graphX, graphY, graphWidth, graphHeight);

  context.fillStyle = bg;
  context.globalAlpha = 0.9;
  context.fillRect(graphX, graphY, graphWidth, graphHeight);

  let min = Infinity;
  let max = 0;

  return {
    dom: canvas,
    update: function(value: number, maxValue: number) {
      min = Math.min(min, value);
      max = Math.max(max, value);

      context.fillStyle = bg;
      context.globalAlpha = 1;
      context.fillRect(0, 0, width, graphY);
      context.fillStyle = fg;
      context.fillText(
        Math.round(value).toString().padStart(2, '0')
        + ' '
        + name
        + ' ('
        + Math.round(min)
        + '-'
        + Math.round(max)
        + ')',
        textX,
        textY,
      );

      context.drawImage(
        canvas,
        graphX + barWidth,
        graphY,
        graphWidth - barWidth,
        graphHeight,
        graphX,
        graphY,
        graphWidth - barWidth,
        graphHeight,
      );

      context.fillRect(graphX + graphWidth - barWidth, graphY, barWidth, graphHeight);

      context.fillStyle = bg;
      context.globalAlpha = 0.9;
      context.fillRect(
        graphX + graphWidth - barWidth,
        graphY,
        barWidth,
        Math.round((
          1 - (
            value / maxValue
          )
        ) * graphHeight),
      );
    },
  };
};

@Component({
  selector: 'core-debug-performance',
  templateUrl: './debug-performance.component.html',
  styleUrls: ['./debug-performance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DebugItemsComponent,
  ]
})
export class DebugPerformanceComponent {

  private frames = 0;
  private beginTime = (
    performance || Date
  ).now();
  private prevTime = (
    performance || Date
  ).now();

  private fpsTop = 120;
  private msTop = 1000 / 4;
  private mbTop = performance && performance.memory ? (
    performance.memory.jsHeapSizeLimit / 1048576
  ) / 4 : 120;

  private readonly wrapper = viewChild<ElementRef<HTMLDivElement>>('monitoring');
  private readonly wrapper$ = toObservable(this.wrapper);

  private readonly fpsPanel = Panel('FPS', '#0ff', '#002', 246, 80);
  private readonly msPanel = Panel('MS', '#0f0', '#020', 246, 80);
  private readonly mbPanel = Panel('MB', '#f08', '#201', 246, 80);

  constructor(
    @Inject(CORE_DEVICE_TIMEZONE) public readonly deviceTimezone: string,
    @Inject(CORE_DEVICE_LOCALE) public readonly deviceLocale: string,
    @Inject(CORE_DEVICE_NUMBERING_SYSTEM) public readonly deviceNumberingSystem: string,
    public readonly destroyRef: DestroyRef,
  ) {
    this.wrapper$.pipe(
      filter((ref): ref is ElementRef<HTMLDivElement> => !!ref),
      map((ref) => ref.nativeElement),
      map((element) => {
        element.appendChild(this.fpsPanel.dom);
        element.appendChild(this.msPanel.dom);
        element.appendChild(this.mbPanel.dom);

        return {
          fps: this.fpsPanel,
          ms: this.msPanel,
          mb: this.mbPanel,
        };
      }),
      switchMap((config) => {
        return animationFrames().pipe(
          map(() => config),
        );
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((panels) => {
      const time = (
        performance || Date
      ).now();

      this.frames++;

      if (time > this.prevTime + 1000) {
        const fps = this.frames * 1000 / (
          time - this.prevTime
        );

        if (fps > this.fpsTop) {
          this.fpsTop = fps;
        }

        panels.fps.update(fps, this.fpsTop);

        this.prevTime = time;
        this.frames = 0;

        if (
          performance &&
          performance.memory
        ) {
          const memory = performance.memory.usedJSHeapSize / 1048576;

          if (memory > this.mbTop) {
            this.mbTop = memory;
          }

          panels.mb.update(memory, this.mbTop);
        }
      }

      panels.ms.update(time - this.beginTime, this.msTop);

      this.beginTime = time;
    });

    this.destroyRef.onDestroy(() => {
      this.fpsPanel.dom.remove();
      this.msPanel.dom.remove();
      this.mbPanel.dom.remove();
    });
  }

}
