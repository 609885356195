import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { MenuRaw } from '../models';
import { Repository } from '../database/repository';

@Injectable({
  providedIn: 'root',
})
export class MenuRepository extends Repository<MenuRaw, 'id'> {

  constructor(db: NgxIndexedDBService) {
    super(db, 'menus', 'id');
  }

}
