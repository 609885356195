import {
  CORE_APP_ENV,
  CORE_APP_NAME,
  CORE_APP_VERSION,
  CORE_DEVICE_LOCALE,
  CORE_DEVICE_TIMEZONE,
  CORE_STARTUP_DATE,
  CORE_WEBSOCKET_BASE_URL,
} from '../../core.tokens';
import { DestroyRef, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, merge, of, Subject, Subscription, timer } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { AuthService } from './auth.service';
import { NetworkService } from './network.service';
import { Intercom } from './intercom.service';
import { InteractionService } from './interaction.service';
import { WatchdogService } from './watchdog.service';
import { UpdateService } from './update.service';
import { MessageMap, WebSocketResponse } from '../models/websocket.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SwarmService } from './swarm.service';

@Injectable()
export class WebsocketService {

  private wsSub: Subscription | null = null;
  private timeoutSub: Subscription | null = null;
  private heartbeatSub: Subscription | null = null;

  private readonly logger = this.watchdog.tag('Websocket Service', 'magenta');

  public readonly status$ = new BehaviorSubject<boolean>(false);
  public readonly connectionCount$ = new BehaviorSubject<number>(0);
  public readonly lastConnectionAt$ = new BehaviorSubject<Date | null>(null);
  public readonly lastDisconnectionAt$ = new BehaviorSubject<Date | null>(null);

  private readonly ws$ = webSocket<WebSocketResponse>({
    url: `${ this.websocketBaseUrl }/ws/main`,
    openObserver: {
      next: () => {
        this.logger.debug('Open');
        this.sendAuthToken();
      },
    },
    closingObserver: {
      next: () => this.logger.debug('Closing'),
    },
    closeObserver: {
      next: () => {
        this.logger.debug('Close');
        this.status$.next(false);
        this.lastDisconnectionAt$.next(new Date());
      },
    },
  });

  private readonly messageSubject = new Subject<WebSocketResponse>();

  public readonly messages$ = this.messageSubject.asObservable();

  constructor(
    @Inject(CORE_STARTUP_DATE) private readonly startupDate: Date,
    @Inject(CORE_DEVICE_LOCALE) private readonly deviceLocale: string,
    @Inject(CORE_DEVICE_TIMEZONE) private readonly deviceTimezone: string,
    @Inject(CORE_APP_NAME) private readonly appName: string,
    @Inject(CORE_APP_VERSION) private readonly appVersion: string,
    @Inject(CORE_APP_ENV) private readonly appEnv: string,
    @Inject(CORE_WEBSOCKET_BASE_URL) private readonly websocketBaseUrl: string,
    private readonly destroyRef: DestroyRef,
    private readonly auth: AuthService,
    private readonly swarm: SwarmService,
    private readonly update: UpdateService,
    private readonly watchdog: WatchdogService,
    private readonly interaction: InteractionService,
    private readonly network: NetworkService,
    private readonly intercom: Intercom,
  ) {}

  public get isConnected(): boolean {
    return this.status$.getValue();
  }

  public initialize(): void {
    this.logger.info('Initialize');

    combineLatest([
      this.swarm.leader$,
      this.auth.authorized$,
      this.network.status$.pipe(
        distinctUntilChanged(),
      ),
      this.auth.demoMode$.pipe(
        distinctUntilChanged(),
      ),
    ]).pipe(
      tap(([leader, authorized, status, demo]) => {
        if (!leader || !authorized || !status || demo) {
          this.unsubscribeAll();
        }
        else {
          this.initWebSocket();
        }
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.swarm.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.intercom.messages$),
      filter(message => message.event.startsWith('demo.')),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((message) => {
      switch (message.event) {
        case 'demo.mode':
          this.status$.next(true);
          this.auth.demoMode$.next(true);
          break;
        case 'demo.events':
          this.messageSubject.next(message.data);
          break;
      }
    });
  }

  public send<K extends keyof MessageMap>(type: K, data: MessageMap[K]): void {
    this.logger.debug('Send:', type, data);
    this.ws$.next({ type, data });
  }

  public sendAuthToken(): void {
    this.auth.token$.pipe(
      take(1),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((token) => {
      this.send('login', {
        token,
        metadata: {
          device: {
            locale: this.deviceLocale,
            timezone: this.deviceTimezone,
            screen: {
              width: window.screen.width,
              height: window.screen.height,
            },
          },
          app: {
            name: this.appName,
            version: this.appVersion,
            environment: this.appEnv,
            startupDate: this.startupDate.toUTCString(),
            selfUpdate: {
              enabled: this.update.enabled,
              checkStrategy: this.update.updateCheckStrategyTitle,
              activateStrategy: this.update.updateActivateStrategyTitle,
            },
            userInteraction: {
              idle: {
                delay: this.interaction.idleDelay,
              },
            },
          },
        },
      });
    });
  }

  private unsubscribeAll(): void {
    this.wsSub?.unsubscribe();
    this.wsSub = null;
    this.timeoutSub?.unsubscribe();
    this.timeoutSub = null;
    this.heartbeatSub?.unsubscribe();
    this.heartbeatSub = null;
  }

  private initWebSocket(): void {
    this.unsubscribeAll();

    const share$ = this.ws$.pipe(
      filter((response) => !!(response?.type)),
      catchError((error) => {
        this.logger.error('WebSocket:', error);
        return EMPTY;
      }),
      share(),
    );

    this.timeoutSub = merge(of('init'), share$).pipe(
      switchMap(() => of('timeout').pipe(delay(10000))),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((msg) => {
      console.log(`WebSocket: ${ msg }`);
      this.initWebSocket();
    });

    this.heartbeatSub = timer(1000, 5000).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.send('heartbeat', { time: Date.now().toString() });
    });

    this.wsSub = share$.pipe(
      tap((response) => {
        this.logger.debug(response.type, response.data);

        this.messageSubject.next(response);

        switch (response.type) {
          case 'loginSuccessful':
            this.logger.debug('Received: Login successful.');

            this.status$.next(true);
            this.connectionCount$.next(this.connectionCount$.getValue() + 1);
            this.lastConnectionAt$.next(new Date());

            break;

          case 'loginError':
          case 'deleteTable':
            this.logger.debug('Received: ' + response.type === 'loginError' ? 'Login error.' : 'Delete table.');
            this.auth.logout();
            this.ws$.complete();
            break;

          case 'tableInfo':
            this.logger.debug('Received: Table info.', response.data);

            if (response.data.clickableMediaConfig) {
              response.data.clickableMediaConfig = JSON.parse(response.data.clickableMediaConfig);
            }
            break;

          case 'heartbeat':
            this.logger.debug('Received: Heartbeat', response.data?.time);
            break;

          case 'error':
            this.logger.error('WebSocket error:', response.data);
            break;

          default:
            this.logger.debug('Received: Unknown message', response);
            break;
        }
      }),
      catchError((error) => {
        this.logger.error('WebSocket error:', error);

        this.initWebSocket();
        return EMPTY;
      }),
      finalize(() => this.logger.info('WebSocket: Complete')),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
}
