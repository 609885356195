<core-debug-ui-items>
  <core-debug-ui-field label="Timezone">{{ deviceTimezone }}</core-debug-ui-field>
  <core-debug-ui-field label="Locale">{{ deviceLocale | uppercase }}</core-debug-ui-field>
  <core-debug-ui-field label="Numbering System">{{ deviceNumberingSystem | titlecase }}</core-debug-ui-field>
  <core-debug-ui-field label="Screen">
    {{ screen.width }} x {{ screen.height }} px
    - Viewport:
    @if (viewport(); as viewport) {
      {{ viewport.width }} x {{ viewport.height }} px
    } @else {
      N/A
    }
  </core-debug-ui-field>
</core-debug-ui-items>
