import { Injectable } from '@angular/core';
import { concatMap, forkJoin, from, mergeMap, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { FileCacheModel, IFileCache } from '../models';
import { FileDownloaderService } from './file-downloader.service';
import { FileCacheRepository } from '../repositories/file-cache.repository';

@Injectable()
export class FileCacheService {

  public constructor(
    private readonly downloader: FileDownloaderService,
    private readonly filesCacheRepository: FileCacheRepository,
  ) {}

  public downloadFile(url: string): Observable<IFileCache> {
    const fileDownloader$ = this.downloader.downloadAsBlob(url).pipe(
      mergeMap((blob) => this.add(url, blob)),
      map((file) => new FileCacheModel(file))
    );

    return this.filesCacheRepository.one$(url).pipe(
      take(1),
      switchMap((file) => {
        if (file) {
          return of(file);
        }

        return fileDownloader$;
      }),
    );
  }

  public downloadFiles(urls: string[]): Observable<IFileCache[]> {
    return forkJoin(
      urls.map((url) => this.downloadFile(url)),
    ).pipe(
      map((files) => {
        return files.sort((a, b) => urls.indexOf(a.url) - urls.indexOf(b.url));
      }),
    );
  }

  public add(fileUrl: string, blob: Blob): Observable<IFileCache> {
    return this.filesCacheRepository.add$({
      url: fileUrl,
      blob,
      createdAt: Date.now()
    }).pipe(
      map((entry) => entry)
    );
  }

  public delete(url: string): Observable<boolean> {
    return this.filesCacheRepository.delete$(url);
  }

  public bulkDelete(urls: string[]): Observable<unknown> {
    return from(urls).pipe(
      concatMap((url) => this.filesCacheRepository.delete$(url)),
    )
  }

}
