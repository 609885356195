import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DebugFieldComponent, DebugItemsComponent } from '../../ui';
import { SwarmService } from '../../../../services';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import animals from '../../data/animals.json';

@Component({
  selector: 'core-debug-swarm',
  templateUrl: './debug-swarm.component.html',
  styleUrls: ['./debug-swarm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DebugItemsComponent,
    DebugFieldComponent,
  ]
})
export class DebugSwarmComponent {

  public readonly leader = toSignal(
    this.swarm.leader$.pipe(
      map((leader) => !!leader),
    ), {
      initialValue: false,
    },
  );

  public readonly members = toSignal(
    this.swarm.members$.pipe(
      map((members) => {
        const result: {
          id: string;
          name: string;
          self: boolean;
          leader: boolean;
        }[] = [];

        members.forEach((member, id) => {
          result.push({
            id,
            name: this.getAnimalNameFromId(id),
            self: member.self,
            leader: member.leader,
          });
        });

        return result;
      }),
    ),
    {
      initialValue: [],
    },
  );

  constructor(
    private readonly swarm: SwarmService,
  ) {}

  private getAnimalNameFromId(id: string): string {
    const length = id.length;

    let sum = 0;
    for (let i = 0; i < length; i++) {
      sum += id.charCodeAt(i);
    }

    return animals[sum % animals.length] as unknown as string;
  }

}
