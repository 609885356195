import { ChangeDetectionStrategy, Component, DestroyRef, input } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { BehaviorSubject, EMPTY, switchMap, timer } from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { AnimationItem } from 'lottie-web';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'core-interacts-present',
  templateUrl: './interacts-present.component.html',
  imports: [
    LottieComponent,
  ],
  styleUrls: ['./interacts-present.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractsPresentComponent {

  public readonly animationDelay = input(40_000);

  public readonly animationOptions: AnimationOptions = {
    path: '/app/animations/touch.json',
    loop: false,
    autoplay: false,
  };

  private readonly animationEventSubject = new BehaviorSubject<'start' | 'restart'>('start');
  private readonly animationItemSubject = new BehaviorSubject<AnimationItem | null>(null);

  private readonly animationDelay$ = toObservable(this.animationDelay);

  constructor(
    private readonly destroyRef: DestroyRef,
  ) {
    this.animationItemSubject.asObservable().pipe(
      switchMap((animation) => {
        if (!animation) {
          return EMPTY;
        }

        return this.animationEventSubject.asObservable().pipe(
          tap((event) => {
            if (event === 'restart') {
              animation.goToAndStop(0);
            }
          }),
          switchMap(() => this.animationDelay$),
          switchMap((delay) => timer(delay)),
          map(() => animation),
        );
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((animation) => {
      animation.goToAndPlay(0);
    });
  }

  public onAnimationCreated(animation: AnimationItem): void {
    this.animationItemSubject.next(animation);
  }

  public onAnimationComplete(): void {
    this.animationEventSubject.next('restart');
  }

}
