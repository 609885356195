import { DestroyRef, Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { UserActivity } from '../models';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  repeatWhen,
  retryWhen,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { NetworkService } from './network.service';
import { CORE_APP_ENV, CORE_APP_VERSION } from '../../core.tokens';
import { WatchdogService } from './watchdog.service';
import { UserActivityRepository } from '../repositories/user-activity.repository';
import { AuthService } from './auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SwarmService } from './swarm.service';

export const CORE_USER_ACTIVITY_SECTION = new InjectionToken<BehaviorSubject<string>>('CORE_USER_ACTIVITY_SECTION');

@Injectable()
export class UserActivityService {

  private readonly logger = this.watchdog.tag('User Activity', 'black');

  constructor(
    @Inject(CORE_APP_ENV) private readonly appEnv: string,
    @Inject(CORE_APP_VERSION) private readonly appVersion: string,
    private readonly destroyRef: DestroyRef,
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly swarm: SwarmService,
    private readonly userActivityRepository: UserActivityRepository,
    private readonly network: NetworkService,
    private readonly watchdog: WatchdogService,
  ) {
    this.swarm.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.auth.authorized$),
      switchMap((authorized) => {
        if (!authorized) {
          return of('Unauthorized');
        }

        return this.sendLogsPeriodical(60 * 1000);
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  public trackClick(event: MouseEvent, screen: string, section: string, target: any): void {
    this.userActivityRepository.add$({
      app: this.appEnv,
      appVersion: this.appVersion,
      action: event.type,
      target,
      screen,
      section,
      screenX: event.screenX,
      screenY: event.screenY,
      capturedAt: new Date(),
      sentAt: undefined
    }).subscribe({
      error: (err) => this.logger.error('Error adding data:', err),
    });
  }

  public sendLogsPeriodical(period: number): Observable<unknown> {
    const repeatDelay = () => this.network.status$.pipe(
      distinctUntilChanged(),
      filter((s) => s),
      tap(() => this.logger.info(`Sending delay until ${ new Date(Date.now() + period).toLocaleString() }`)),
      delay(period),
      tap(() => this.logger.info('Sending restart')),
    );

    return this.sendLogs().pipe(
      repeatWhen((completed) => completed.pipe(switchMap(repeatDelay))),
      retryWhen((errors) => errors.pipe(switchMap(repeatDelay))),
    );
  }

  private deleteSentLogs(): Observable<unknown> {
    return this.userActivityRepository.all$().pipe(
      map((logs) => logs.filter(log => log.sentAt !== undefined)),
      mergeMap(logs => {
        if (logs.length === 0) {
          return of();
        }
        const deletionObservables: Observable<boolean>[] = logs.map(log => {
          if (log.id !== undefined) {
            return this.userActivityRepository.delete$(log.id);
          }
          else {
            return of();
          }
        });
        return forkJoin(deletionObservables);
      }),
    );
  }

  private sendLogs(): Observable<any> {
    return this.userActivityRepository.all$().pipe(
      take(1),
      map(logs => {
        if (logs) {
          return logs.filter(log => log.sentAt === undefined)
        } else {
          return [];
        }
      }),
      mergeMap(logs => {
        if (logs.length <= 10) {
          return EMPTY;
        }

        return this.http.post<UserActivity[]>('@api_host/clicks/report', { records: logs }).pipe(
          tap(response => {
            this.logger.log('Logs sent successfully', response);
            this.updateSentLogs(logs);
          }),
          catchError(error => {
            this.logger.error('Error sending logs:', error);
            return EMPTY;
          }),
        );
      }),
    );
  }

  private updateSentLogs(logs: UserActivity[]): void {
    const currentTime = new Date();
    const updatedLogs: Observable<UserActivity>[] = logs.map(log => {
      log.sentAt = currentTime;
      return this.userActivityRepository.update$(log);
    });

    forkJoin(updatedLogs).subscribe({
      next: () => {
        this.logger.info('All sent logs are marked with sent time');
        this.deleteSentLogs().subscribe({
          next: () => {
            this.logger.info('All sent logs have been deleted successfully');
          },
          error: err => this.logger.error('Error delete logs:', err),
        });
      },
      error: err => this.logger.error('Error updating logs:', err),
    });
  }

}
