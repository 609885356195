import { RepositoryKey } from './repository.types';
import { OperatorFunction, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Repository } from './repository';

export const hasOne = <
  T extends Record<string, any>,
  R extends T & { [x in NK]: MT | undefined },
  KN extends keyof T,
  MT extends Record<string, any>,
  MKN extends keyof MTK,
  MI extends boolean = false,
  MTN extends MT | Omit<MT, MKN> = MI extends false ? MT : Omit<MT, MKN>,
  MTK extends RepositoryKey<MT> = RepositoryKey<MT>,
  MKV extends MTK[MKN] = MTK[MKN],
  NK extends string = string
>(
  repository: Repository<MT, MKN, MI, MTN, MTK, MKV>,
  foreignKey: Omit<MKN, keyof MTK>,
  localKey: KN,
  newKey: NK,
): OperatorFunction<T | T[] | undefined, R | R[] | undefined> => {
  return (source) => source.pipe(
    mergeMap((value) => {
      if (value === undefined) {
        return of(value);
      }

      if (Array.isArray(value)) {
        return repository.all$().pipe(
          map((foreignValues) => {
            return value.map((value) => {
              const foreignKeyValue = value[localKey];

              return {
                ...value,
                [newKey]: foreignValues.find(
                  (foreignValue) => foreignValue[foreignKey as any] === foreignKeyValue,
                ),
              } as R;
            });
          }),
        );
      }

      const foreignKeyValue = value[localKey];

      return repository.one$(foreignKeyValue).pipe(
        map((foreignKeyValue) => ({
          ...value,
          [newKey]: foreignKeyValue,
        } as R)),
      );
    }),
  );
};

export const hasMany = <
  T extends Record<string, any>,
  R extends T & { [x in NK]: MT[] },
  KN extends keyof T,
  MT extends Record<string, any>,
  MKN extends keyof MTK,
  MI extends boolean = false,
  MTN extends MT | Omit<MT, MKN> = MI extends false ? MT : Omit<MT, MKN>,
  MTK extends RepositoryKey<MT> = RepositoryKey<MT>,
  MKV extends MTK[MKN] = MTK[MKN],
  NK extends string = string
>(
  repository: Repository<MT, MKN, MI, MTN, MTK, MKV>,
  foreignKey: Omit<MKN, keyof MTK>,
  localKey: KN,
  newKey: NK,
): OperatorFunction<T | T[] | undefined, R | R[] | undefined> => {
  return (source) =>
    source.pipe(
      mergeMap((value) => {
        if (value === undefined) {
          return of(value);
        }

        if (Array.isArray(value)) {
          return repository.all$().pipe(
            map((foreignValues) => {
              return value.map((item) => {
                const foreignKeyValue = item[localKey];

                return {
                  ...item,
                  [newKey]: foreignValues.filter((foreignValue) =>
                    Array.isArray(foreignKeyValue)
                      ? foreignKeyValue.includes(foreignValue[foreignKey as any])
                      : foreignValue[foreignKey as any] === foreignKeyValue,
                  ),
                } as R;
              });
            }),
          );
        }

        const foreignKeyValues = value[localKey];

        return repository.all$().pipe(
          map((foreignValues) => ({
            ...value,
            [newKey]: foreignValues.filter((foreignValue) =>
              Array.isArray(foreignKeyValues)
                ? foreignKeyValues.includes(foreignValue[foreignKey as any])
                : foreignValue[foreignKey as any] === foreignKeyValues,
            ),
          } as R)),
        );
      }),
    );
};
