import { DestroyRef, Injectable } from '@angular/core';
import { of, shareReplay } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { isNull, isUndefined } from '../utils';
import { AuthService } from './auth.service';
import { FileCacheService } from './file-cache.service';
import { WatchdogService } from './watchdog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WebsocketService } from './websocket.service';
import { KVRepository } from '../repositories';
import { FileCacheRepository } from '../repositories/file-cache.repository';
import { FileCacheModel } from '../models';
import { SwarmService } from './swarm.service';

@Injectable()
export class InvoiceQrcodeImageService {

  private readonly storageKey = 'invoiceQrcodeImage';
  private readonly logger = this.watchdog.tag('Invoice QR-Code Image', 'green');

  public readonly url$ = this.kvRepository.one$(this.storageKey).pipe(
    map((entry) => {
      if (entry?.value) {
        return entry.value as string;
      }

      return null;
    }),
    shareReplay(1),
  );

  public readonly file$ = this.url$.pipe(
    switchMap((url) => {
      if (isNull(url)) {
        return of(null);
      }

      return this.filesCacheRepository.one$(url).pipe(
        map((entry) => {
          if (isUndefined(entry)) {
            return null;
          }

          const file = entry
            ? new FileCacheModel(entry)
            : null;

          return file
            ? this.domSanitizer.bypassSecurityTrustUrl(file.objectUrl)
            : null;
        }),
      );
    }),
    shareReplay(1),
  );

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly auth: AuthService,
    private readonly swarm: SwarmService,
    private readonly watchdog: WatchdogService,
    private readonly filesCacheRepository: FileCacheRepository,
    private readonly kvRepository: KVRepository,
    private readonly domSanitizer: DomSanitizer,
    private readonly webSocketService: WebsocketService,
    private readonly filesCache: FileCacheService,
  ) {}

  public initialize() {
    this.logger.info('Initialize');

    // Clear invoice QR-Code on logout
    this.swarm.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.auth.logouted$),
      switchMap(() => this.clear()),
      tap(() => this.logger.info('Cleared invoice QR-Code on logout')),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    this.swarm.leader$.pipe(
      filter((leader) => !!leader),
      switchMap(() => this.webSocketService.messages$),
      filter((message) => message.type === 'tableInfo'),
      switchMap((response) => {
        if (!response.data.invoiceQRCodeUrl) {
          return this.clear();
        }

        return this.update(response.data.invoiceQRCodeUrl);
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  public update(url: string) {
    return this.url$.pipe(
      take(1),
      switchMap((qrUrl) => {
        if (qrUrl && qrUrl === url) {
          return of(qrUrl);
        }

        return this.clear().pipe(
          switchMap(() => this.kvRepository.update$({
            key: this.storageKey,
            value: url,
          })),
          switchMap(() => this.filesCache.downloadFile(url)),
          tap((value) => this.logger.info('Updated Invoice Qrcode', value)),
        );
      }),
    );
  }

  public clear() {
    return this.kvRepository.one$(this.storageKey).pipe(
      take(1),
      switchMap((qrUrl) => {
        if (isUndefined(qrUrl)) {
          return of(qrUrl);
        }

        return this.filesCacheRepository.delete$(qrUrl.value as string);
      }),
      switchMap(() => this.kvRepository.delete$(this.storageKey)),
    );
  }

}
