@let timer = status();

@if (timer.active || timer.now) {
  <div
    class="happy-hours"
    routerLink="/main/menu"
    coreActivitySection="HappyHours"
    coreUserActivityClickTracking="OpenHappyHours"
  >
    <div class="first">
      <div [innerHTML]="'happyHour.label' | translate"></div>
    </div>
    <div class="second">
      <div class="timer">
        <div>{{ timer.activeCountdownTime }}</div>
      </div>
      <div class="label">
        <div class="menu">
          {{ 'happyHour.viewMenu' | translate }}
        </div>
        @if (timer.now) {
          <div class="timer-label">
            {{ 'happyHour.timeLeft' | translate }}
          </div>
        } @else {
          <div class="timer-label">
            {{ 'happyHour.countdown' | translate }}
          </div>
        }
      </div>
    </div>
  </div>
}
