import {
  AuthService,
  CallWaiterService,
  DeviceWorkScheduleService,
  FileCacheService,
  FileDownloaderService,
  HappyHoursService,
  InteractionService,
  Intercom,
  InvoiceQrcodeImageService,
  MenusService,
  NetworkService,
  RestaurantTableService,
  ScreenTrackingService,
  SpecialsService,
  SwarmService,
  UpdateService,
  UserActivityService,
  WatchdogService,
  WebsocketService,
  WidgetUiConfigRef,
} from './lib/services';

export const provideCoreServices = () => [
  UpdateService,
  NetworkService,
  InteractionService,
  AuthService,
  SwarmService,
  Intercom,
  FileCacheService,
  FileDownloaderService,
  HappyHoursService,
  InvoiceQrcodeImageService,
  WatchdogService,
  MenusService,
  RestaurantTableService,
  SpecialsService,
  WebsocketService,
  WidgetUiConfigRef,
  DeviceWorkScheduleService,
  UserActivityService,
  ScreenTrackingService,
  CallWaiterService,
];
