import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { KeyValue } from '../models';
import { Repository } from '../database/repository';

@Injectable({
  providedIn: 'root',
})
export class KVRepository extends Repository<KeyValue, 'key'> {

  constructor(db: NgxIndexedDBService) {
    super(db, 'keyValue', 'key');
  }

}
