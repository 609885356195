import { AfterContentInit, Component, DestroyRef, ElementRef, HostListener, OnInit } from '@angular/core';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  ActivitySectionDirective,
  HappyHoursService,
  Menu,
  MenuImageComponent,
  MenusService,
  UserActivityClickTrackingDirective,
} from '@core';
import { LoadingComponent } from '@app/components/loading/loading.component';

const mainTabId = 'tabMain';
const happyHoursTabId = 'tabHappyHours';

@Component({
  selector: 'app-menus-tab',
  templateUrl: 'menu-tab.component.html',
  styleUrl: 'menu-tab.component.scss',
  imports: [
    TranslateModule,
    LoadingComponent,
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
    MenuImageComponent,
  ]
})
export class MenuTabComponent implements OnInit, AfterContentInit {

  public readonly tabs = {
    isSticky: false,
    activeId: mainTabId,
    name: '',
  };

  public readonly pinchZoomProperties = {
    wheel: false,
    autoZoomOut: true,
    backgroundColor: 'rgba(240, 240, 243, 0.9)',
  };

  public isLoading = toSignal(this.menusService.loading$, {
    initialValue: true
  });
  public isSyncInProgress = toSignal(this.menusService.sync$, {
    requireSync: true
  });

  public mainMenus: Menu[] = [];
  public mainMenusCurrent: Menu | null = null;

  public happyHoursMenus: Menu[] = [];
  public happyHoursMenusCurrent: Menu | null = null;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly element: ElementRef,
    private readonly router: Router,
    private readonly menusService: MenusService,
    private readonly happyHoursService: HappyHoursService,
  ) {}

  get isBackTabShow(): boolean {
    return (
        this.tabs.activeId === 'tabMain'
        && this.mainMenus.length > 1
        && !!this.mainMenusCurrent
      )
      || (
        this.tabs.activeId === 'tabHappyHours'
        && this.happyHoursMenus.length > 1
        && !!this.happyHoursMenusCurrent
      );
  }

  public get isTabsShow(): boolean {
    return this.isBackTabShow || (
      this.happyHoursMenus.length !== 0
      && this.mainMenus.length !== 0
    );
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.setActiveTab(this.tabs.activeId);
    });
  }

  ngAfterContentInit(): void {
    this.menusService.menusWithPages$.pipe(
      tap((menus) => {
        if (menus) {
          this.updateMenus(menus);
        }
      }),
      switchMap(() => this.happyHoursService.isNow$.pipe(
        take(1),
      )),
      tap((isHH) => {
        isHH =
          (isHH || this.mainMenus.length === 0)
          && this.happyHoursMenus.length !== 0;
        this.setActiveTab(isHH ? happyHoursTabId : mainTabId);
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  @HostListener('scroll', ['$event'])
  onScroll(): void {
    this.tabs.isSticky = this.element.nativeElement.scrollTop >= 20;
  }

  private updateMenus(menus: Menu[]): void {
    this.mainMenus = menus.filter(
      (menu) => !menu.happyHours && !menu.hidden,
    );
    this.happyHoursMenus = menus.filter(
      (menu) => menu.happyHours && !menu.hidden,
    );
    this.clearMenusCurrents();

    if (this.mainMenus.length === 0 && this.happyHoursMenus.length !== 0) {
      this.setActiveTab(happyHoursTabId);
    }

    if (this.mainMenus.length !== 0 && this.happyHoursMenus.length === 0) {
      this.setActiveTab(mainTabId);
    }
  }

  setMainMenusCurrent(menu: Menu | null): void {
    this.mainMenusCurrent = menu;
  }

  setHappyHoursMenusCurrent(menu: Menu | null): void {
    this.happyHoursMenusCurrent = menu;
  }

  clearMenusCurrents(): void {
    this.setMainMenusCurrent(
      this.mainMenus.length === 1 ? this.mainMenus[0] : null,
    );

    this.setHappyHoursMenusCurrent(
      this.happyHoursMenus.length === 1 ? this.happyHoursMenus[0] : null,
    );
  }

  setActiveTab(id: string, name?: string): void {
    this.tabs.activeId = id;
    if (name) {
      this.tabs.name = name;
    }

    this.clearMenusCurrents();

    this.element.nativeElement.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

}
